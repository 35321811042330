<template>
  <div id="remind">
    <Else></Else>
     <div class="remind-box">
        <div class="remind-title">滨江区企业短信预警服务</div>
        <div class="remind-contain">
            <div class="remind-div1">
                <div>
                    <span>公司名称</span>
                    <span>{{companyName}}</span>
                </div>
            </div>
            <div>
                <span>法人/经办人姓名</span>
                <input type="text" placeholder="请输入法人/经办人姓名" v-model="name" />
                <div class="remind-msg">{{nameTip}}</div>
            </div>
            <div class="remind-div2">
                <span>手机号码</span>
                <input type="text" placeholder="请输入您的手机号" v-model="mobile" />
                <div class="remind-msg">{{mobileTip}}</div>
            </div>
            <div id="remind-sun" @click="send">提交</div>
           <div id="remind-tip">温馨提醒：若您已经完成关联企业认证，视同已获取短信</div>
        </div>
        
     </div>
  </div>
</template>

<script>
import Else from '../ui/else.vue';
export default {
  name: 'remind',
  data(){
      return{
       name:'',
       nameTip:'',
       mobile:'',
       mobileTip:''
      }
  },
  watch:{
    mobile(newValue) {
      let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (newValue == "") {
        return (this.mobileTip = "请输入手机号码");
      }
      if (newValue.length != 11 || !reg.test(newValue)) {
        return (this.mobileTip = "手机号格式不正确");
      }
      return (this.mobileTip = "");
    },
    name(newValue) {
      if (newValue == "") {
        return (this.nameTip = "请输入请输入法人/经办人姓名");
      }
      this.nameTip = "";
    },
  },
  components: {
      Else
  },
  computed: {
    companyName() {
      return this.$route.query.company
    }
  },
  methods:{
    tip(type,msg){
          return this.$message({
              type:type,
              message:msg,
              showClose:true,
              duration:3000
          })
      },
     send(){
     if (this.name == "") {
          this.nameTip = "请输入法人/经办人姓名";
          return false;
        } else if (this.mobile == "") {
          this.mobileTip = "请输入您的手机号";
          return false;
        } else if (this.mobileTip == "手机号格式不正确") {
          return false;
        } else {
          this.$http
            .post(
              this.GLOBAL.new_url + "/user/service/cooperator/msgRemind",
              {
              
                userBusinessCode:this.companyName,
                mobile: this.mobile,
                legalName:this.name
              }
            ).then((res) => {
                console.log(res)
              if (res.data.code == "200") {
                this.tip("success", "提交成功");
                // this.name="";
                // this.nameTip="";
                // this.mobile="";
                // this.mobileTip="";
              } else {
                // this.tip("error", res.data.msg);
              }
            });
        }
   },
       
  },
  mounted(){
       //子向父发送数据
      this.$nextTick(()=>{
        // var height =  document.documentElement.scrollHeight;
        // console.log(height)
          window.parent.postMessage(
          {
            data: 560,
          },
          "*"
        );
      })
  }
}

</script>

<style scoped>
#remind{
    padding: 30px;
}
.remind-box{
    width: 1015px;
    margin: 0 auto;
}
.remind-title{
    height: 40px;
    font-size: 20px;
    text-align: center;
    border-bottom:1px solid #D4D4D4;
    margin-bottom: 40px;
}
.remind-contain{
    width: 1015px;
    padding: 50px 0;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(85,84,91,0.2700);
    border-radius: 4px;
}
.remind-contain>div{
    width: 525px;
    margin: 0 auto;
}
.remind-contain>div>input{
    width: 345px;
    height: 34px;
    padding-left: 10px;
    outline: none;
    border-radius: 5px;
    border: 1px solid #979797;
    margin-left: 45px;
}
.remind-div1>div{
    margin:0 0 45px 55px;
}
.remind-div1>div>span{
    margin-right: 45px;
}
.remind-div2>span{
    margin-left: 56px;
}
.remind-msg{
    height: 45px;
    line-height: 45px;
    color: red;
    font-size: 14px;
    margin-left: 177px;
}
#remind-sun{
    width: 136px;
    height: 27px;
    color: #FFFFFF;
    line-height: 27px;
    background: #FF8328;
    border-radius: 9px;
    margin: 0 0 45px 500px;
    cursor: pointer;
    text-align: center;
}
#remind-tip{
    text-align: center;
    font-size: 12px;
     margin-left: 300px;
}
</style>
